import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
}                                 from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA }        from '@angular/material/dialog';
import {
  distinctUntilChanged,
  Subject,
  takeUntil,
}                                 from 'rxjs';
import {
  CreditorRemittanceInformationType,
  PaymentData,
  PaymentFormData,
}                                 from '~domain/types';
import {
  DialogButton,
  DialogConfig,
}                                 from '~shared/components';
import { RbDialogForm }           from '~shared/directives';
import { ValidatorService }       from '../core/validators';
import { EditBeneficiaryRequest } from './edit-beneficiary.component';
import { PaymentService }         from './payment.service';

export type AddPaymentRequest = {
  paymentFormData: PaymentFormData,
  reloadPayments?: boolean,
  id?: string,
  documentId?: string,
}

@Component({
  selector: 'rb-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDialogComponent extends RbDialogForm implements OnInit, OnDestroy {

  override form = this.fb.group({
    payment: this.fb.control<PaymentFormData>(this.request.paymentFormData),
  });
  addPaymentLoading$ = this.paymentService.paymentSaving$;
  paymentDocumentType$ = this.paymentService.paymentDocumentType$;
  paymentDocumentUrl$ = this.paymentService.paymentDocumentUrl$;
  paymentClients$ = this.paymentService.paymentClients$;
  paymentAccounts$ = this.paymentService.paymentAccounts$;
  beneficiaries$ = this.paymentService.beneficiaries$;
  beneficiariesLoading$ = this.paymentService.beneficiariesLoading$;
  beneficiaryEditing$ = this.paymentService.beneficiaryEditing$;
  addingAnotherPayment = false;

  dialogConfig: DialogConfig = {
    title: this.request.id
      ? 'APP.EDIT_PAYMENT_DIALOG.TITLE'
      : (this.request.documentId ? 'APP.DOCUMENT_PAYMENT_DIALOG.TITLE' : 'APP.ADD_PAYMENT_DIALOG.TITLE'),
    beta: !!this.request.documentId,
    closeable: true,
    errorKey$: this.errorKey$,
    errorContext: 'APP.ADD_PAYMENT_DIALOG.ERROR',
    buttons: [
      {
        id: DialogButton.Cancel,
        label: 'APP.BUTTON.CANCEL.LABEL',
        icon: 'cross',
        style: 'secondary',
      },
      {
        id: DialogButton.OK,
        label: 'APP.BUTTON.CONFIRM.LABEL',
        icon: 'check',
        style: 'primary',
        autoClose: false,
        disabled$: this.submitDisabled$,
        isLoading$: this.addPaymentLoading$,
      },
    ],
  };

  private destroy$ = new Subject<void>();

  constructor(
    protected override readonly fb: NonNullableFormBuilder,
    private readonly paymentService: PaymentService,
    protected override readonly validatorService: ValidatorService,
    @Inject(MAT_DIALOG_DATA) public request: AddPaymentRequest) {
    super(fb, validatorService);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.addPaymentLoading$
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(loading => {
        if (this.addingAnotherPayment && !loading) {
          this.form.controls.payment.setValue({
            clientId: this.form.controls.payment.value.clientId,
            accountId: this.form.controls.payment.value.accountId,
            amount: null,
            executionDate: null,
            remittanceInformation: '',
            remittanceInformationType: CreditorRemittanceInformationType.unstructured,
            beneficiary: null,
            anotherPayment: true,
            editBeneficiary: null,
          });
          this.addingAnotherPayment = false;
        }
      });
  }

  fetchBeneficiaries(clientId: string) {
    this.paymentService.fetchBeneficiaries(clientId);
  }

  saveBeneficiary(editBeneficiaryRequest: EditBeneficiaryRequest) {
    const { beneficiary, clientId } = editBeneficiaryRequest;
    if (beneficiary.id !== null) {
      if (beneficiary.singleUse) {
        this.paymentService.deleteBeneficiary(beneficiary.id, clientId);
      } else {
        this.paymentService.updateBeneficiary(beneficiary.id, clientId, beneficiary);
      }
    } else if (!beneficiary.singleUse) {
      this.paymentService.addBeneficiary(clientId, beneficiary);
    }
  }

  onDialogButtonClick(dialogButton: DialogButton) {
    if (dialogButton === DialogButton.OK && this.form.valid) {
      this.form.markAllAsTouched();
      const paymentFormData: PaymentData = {
        id: this.request.id,
        clientId: this.form.controls.payment.value.clientId,
        accountId: this.form.controls.payment.value.accountId,
        remittanceInformation: this.form.controls.payment.value.remittanceInformation,
        remittanceInformationType: this.form.controls.payment.value.remittanceInformationType,
        counterpartName: this.form.controls.payment.value.beneficiary?.counterpartName,
        counterpartReference: this.form.controls.payment.value.beneficiary?.counterpartReference,
        executionDate: this.form.controls.payment.value.executionDate,
        amount: this.form.controls.payment.value.amount!,
      };
      this.paymentService.savePayment(paymentFormData, this.form.controls.payment.value.anotherPayment, this.request.reloadPayments);
      this.addingAnotherPayment = this.form.controls.payment.value.anotherPayment;
    }
  }

}
