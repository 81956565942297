import { DOCUMENT }                      from '@angular/common';
import {
  Inject,
  Injectable,
}                                        from '@angular/core';
import { MatDialog }                     from '@angular/material/dialog';
import { Router }                        from '@angular/router';
import {
  Actions,
  createEffect,
  ofType,
}                                        from '@ngrx/effects';
import { routerNavigatedAction }         from '@ngrx/router-store';
import { TranslateService }              from '@ngx-translate/core';
import {
  catchError,
  concatMap,
  filter,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
}                                        from 'rxjs';
import {
  BeneficiaryType,
  mimeType,
}                                        from '~domain/enums';
import {
  Beneficiary,
  CreditorRemittanceInformationType,
  PaymentFormData,
  toBeneficiaryFormData,
}                                        from '~domain/types';
import { AddClientApiService }           from '../add-client/add-client-api.service';
import { LOCAL_STORAGE }                 from '../app.constants';
import { AppRoute }                      from '../app.route.enum';
import {
  CounterApiService,
  DocumentApiService,
  OfficeApiService,
  Toast,
  ToastService,
  UserApiService,
}                                        from '../core';
import { IsabelApiService }              from '../isabel/isabel-api.service';
import { IsabelRedirectDialogComponent } from '../isabel/isabel-redirect-dialog.component';
import { MaintenanceModeApiService }     from '../maintenance-mode/maintenance-mode-api.service';
import { PaymentApiService }             from '../payment/payment-api.service';
import { PaymentDialogComponent }        from '../payment/payment-dialog.component';
import { RespondToDocumentApiService }   from '../respond-to-document/respond-to-document-api.service';
import { Xs2aApiService }                from '../xs2a/xs2a-api.service';
import { Xs2aDialogComponent }           from '../xs2a/xs2a-dialog.component';
import { Xs2aRedirectDialogComponent }   from '../xs2a/xs2a-redirect-dialog.component';
import * as AppActions                   from './app.actions';

@Injectable()
export class AppEffects {
  fetchColorMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchColorMode),
      map(() => AppActions.fetchColorModeResult({
        colorMode: this.localStorage.getItem('colorMode') as 'dark' | 'light' | null,
      })),
    ),
  );

  setColorMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.setColorMode),
      map(({ colorMode }) => {
        if (colorMode) this.localStorage.setItem('colorMode', colorMode);
        else this.localStorage.removeItem('colorMode');
        return AppActions.setColorModeResult({ colorMode });
      }),
    ),
  );

  fetchMaintenanceMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchMaintenanceMode),
      switchMap(() => this.maintenanceModeApiService.fetchMaintenanceModes()),
      map((maintenanceModes) => AppActions.fetchMaintenanceModeResult({
        maintenanceMode: maintenanceModes.global ?? false,
      })),
    ),
  );

  fetchAccountUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchAccountUser),
      switchMap(() => this.userApiService.fetchAccountUser()),
      tap(({ language }) => this.translateService.use(language)),
      map(user => AppActions.fetchAccountUserResult({ user })),
    ),
  );

  fetchClientBadgeCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchClientBadgeCount, AppActions.respondToDocumentResult),
      switchMap(() => this.counterApiService.getNumberOfClientsWithNotifications()),
      map(count => AppActions.fetchClientBadgeCountResult({ count })),
    ),
  );

  fetchNotificationBadgeCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchNotificationBadgeCount, AppActions.respondToDocumentResult),
      switchMap(() => this.counterApiService.getNumberOfOpenNotifications()),
      map(count => AppActions.fetchNotificationBadgeCountResult({ count })),
    ),
  );

  fetchPaymentBadgeCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchPaymentBadgeCount, AppActions.addPaymentResult),
      switchMap(() => this.counterApiService.getNumberOfQueuedPayments()),
      map(count => AppActions.fetchPaymentBadgeCountResult({ count })),
    ),
  );

  fetchUnlinkedAccountsBadgeCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchUnlinkedAccountsBadgeCount),
      switchMap(() => this.counterApiService.getNumberOfUnlinkedAccounts()),
      map(count => AppActions.fetchUnlinkedAccountsBadgeCountResult({ count })),
    ),
  );

  fetchAccountsWithWarningBadgeCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchAccountsWithWarningBadgeCount),
      switchMap(() => this.counterApiService.getNumberOfAccountsWithWarning()),
      map(count => AppActions.fetchAccountsWithWarningBadgeCountResult({ count })),
    ),
  );

  fetchOffice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchAccountUserResult),
      switchMap(({ user }) => this.officeApiService.fetchOffice(user.officeId)),
      map(office => AppActions.fetchOfficeResult({ office })),
    ),
  );

  closeDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.closeDialog),
      tap(({ message }) => {
        this.matDialog.closeAll();
        if (message) {
          this.toastService.show(Toast.Success, message);
        }
      }),
    ), { dispatch: false });

  createClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.createClient),
      concatMap(({ client }) =>
        this.addClientApiService.createClient(client).pipe(
          map(id => AppActions.createClientResult({ id, accountId: client.initialAccountId })),
          catchError(() => of(AppActions.createClientError())),
        ),
      ),
    ),
  );

  closeCreateClientDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.createClientResult),
      map(() => AppActions.closeDialog({ message: 'ADD_CLIENT' })),
    ),
  );

  navigateAfterCreatedClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.createClientResult),
      tap(({ id, accountId }) =>
        this.router.navigate(
          [accountId ? '/bank-accounts/' + accountId : '/clients/' + id],
          { onSameUrlNavigation: 'reload' },
        ),
      ),
    ), { dispatch: false });

  downloadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.downloadDocument),
      concatMap(({ id, clientId, name, extension }) => this.documentApiService.downloadDocument(id, clientId)
        .pipe(
          map((file) => {
            const objectURL = window.URL.createObjectURL(
              new Blob([file], { type: mimeType(extension) }),
            );
            const downloadAnchorNode = this.document.createElement('a');
            downloadAnchorNode.setAttribute('href', objectURL);
            downloadAnchorNode.setAttribute('download', `${name}.${extension}`);
            this.document.body.appendChild(downloadAnchorNode); // required for firefox
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
            this.toastService.show(Toast.Success, 'DOCUMENT_DOWNLOADED');
            return AppActions.downloadDocumentResult();
          }),
          catchError(() => of(AppActions.downloadDocumentError())),
        ),
      ),
    ),
  );

  respondToDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.respondToDocument),
      concatMap(({ id, clientId, response, message }) =>
        this.respondToDocumentApiService.respondToDocument(id, response, clientId, message)
          .pipe(
            map(() => AppActions.respondToDocumentResult()),
            catchError(() => of(AppActions.respondToDocumentError())),
          ),
      ),
    ),
  );

  closeRespondToDocumentDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.respondToDocumentResult),
      map(() => AppActions.closeDialog({ message: 'DOCUMENT_RESPONSE_ADDED' })),
    ),
  );

  fetchDocumentPaymentData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchDocumentPaymentData),
      switchMap(({ clientId, documentId, documentType }) => forkJoin([
        this.documentApiService.getDocumentPaymentData(documentId),
        this.documentApiService.downloadDocument(documentId, clientId),
      ]).pipe(
        map(([paymentData, blob]) => AppActions.fetchDocumentPaymentDataResult({
          documentId,
          documentType,
          documentUrl: URL.createObjectURL(blob),
          paymentData: { ...paymentData, clientId },
        })),
        catchError(() => of(AppActions.fetchDocumentPaymentDataError())),
      )),
    ),
  );

  fetchInfoDocumentPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchDocumentPaymentDataResult),
      map(({ documentId, paymentData }) => AppActions.fetchInfoPaymentDialog({
        documentId,
        paymentData,
      })),
    ),
  );

  fetchInfoPaymentDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchInfoPaymentDialog),
      switchMap(({ paymentData, documentId, reloadPayments }) =>
        forkJoin(([
          this.paymentApiService.fetchAccounts(),
          this.paymentApiService.fetchClients(),
          paymentData.clientId ? this.paymentApiService.fetchBeneficiaries(paymentData.clientId) : of([]),
        ])).pipe(
          map(([paymentAccounts, paymentClients, beneficiaries]) => AppActions.fetchInfoPaymentDialogResult({
            paymentData,
            documentId,
            reloadPayments,
            paymentAccounts,
            paymentClients,
            beneficiaries,
          })),
          catchError(() => of(AppActions.fetchInfoPaymentDialogError())),
        ),
      ),
    ),
  );

  openPaymentDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchInfoPaymentDialogResult),
      tap(({
             paymentData,
             documentId,
             reloadPayments,
             beneficiaries,
           }) => {
        const existingBeneficiary = beneficiaries.find(b => b.counterpartName === paymentData.counterpartName && b.counterpartReference === paymentData.counterpartReference);
        const singleUseBeneficiary: Beneficiary | null = (paymentData.counterpartName || paymentData.counterpartReference)
          ? {
            counterpartName: paymentData.counterpartName,
            counterpartReference: paymentData.counterpartReference,
            singleUse: true,
            id: null,
            global: false,
            type: BeneficiaryType.BENEFICIARY,
          } : null;
        const beneficiary = existingBeneficiary ?? singleUseBeneficiary;
        const paymentFormData: PaymentFormData = {
          clientId: paymentData.clientId || '',
          accountId: paymentData.accountId || '',
          amount: paymentData.amount || null,
          executionDate: paymentData.executionDate || null,
          remittanceInformation: paymentData.remittanceInformation || '',
          remittanceInformationType: paymentData.remittanceInformationType || CreditorRemittanceInformationType.unstructured,
          beneficiary,
          anotherPayment: false,
          editBeneficiary: beneficiary ? toBeneficiaryFormData(beneficiary) : null,
        };
        this.matDialog.open(PaymentDialogComponent, {
          disableClose: true,
          data: { paymentFormData, reloadPayments, id: paymentData.id, documentId },
        });
      }),
    ), { dispatch: false });

  addPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.addPayment),
      concatMap(({ paymentData, anotherPayment, reloadPayments }) =>
        this.paymentApiService.addPayment(paymentData)
          .pipe(
            map(() => AppActions.addPaymentResult({ anotherPayment, reloadPayments })),
            catchError(() => of(AppActions.addPaymentError())),
          ),
      ),
    ),
  );

  updatePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.updatePayment),
      concatMap(({ paymentData }) =>
        this.paymentApiService.updatePayment(paymentData)
          .pipe(
            map(() => AppActions.updatePaymentResult({ reloadPayments: true })),
            catchError(() => of(AppActions.updatePaymentError())),
          ),
      ),
    ),
  );


  closeUpdatePaymentDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.updatePaymentResult),
      map(() => AppActions.closeDialog({ message: 'PAYMENT_SAVED' })),
    ),
  );

  closeAddPaymentDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.addPaymentResult),
      filter(({ anotherPayment }) => !anotherPayment),
      map(() => AppActions.closeDialog({ message: 'PAYMENT_SAVED' })),
    ),
  );

  addAnotherPaymentConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.addPaymentResult),
      filter(({ anotherPayment }) => anotherPayment),
      tap(() => this.toastService.show(Toast.Success, 'PAYMENT_SAVED')),
    ), { dispatch: false },
  );

  fetchBeneficiaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchBeneficiaries),
      switchMap(({ clientId }) => {
          if (clientId !== '') {
            return this.paymentApiService.fetchBeneficiaries(clientId)
              .pipe(
                map((beneficiaries) => AppActions.fetchBeneficiariesResult({ beneficiaries })),
                catchError(() => of(AppActions.fetchBeneficiariesError())),
              );
          } else {
            return of(AppActions.fetchBeneficiariesResult({ beneficiaries: [] }));
          }
        },
      ),
    ),
  );

  navigateAfterAddedPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.addPaymentResult, AppActions.updatePaymentResult),
      filter(({ reloadPayments }) => reloadPayments === true),
      tap(() =>
        this.router.navigate(
          ['/payments'],
          {
            onSameUrlNavigation: 'reload',
            queryParamsHandling: 'merge',
          },
        ),
      ),
    ), { dispatch: false });

  updateBeneficiary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.updateBeneficiary),
      concatMap(({ clientId, beneficiaryId, beneficiary }) =>
        this.paymentApiService.updateBeneficiary(beneficiaryId, clientId, beneficiary)
          .pipe(
            switchMap(() => this.paymentApiService.fetchBeneficiaries(clientId).pipe(
              map((beneficiaries) => AppActions.editBeneficiariesResult({ beneficiaries })),
            )),
            catchError(() => of(AppActions.editBeneficiariesError())),
          ),
      ),
    ),
  );

  addBeneficiary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.addBeneficiary),
      concatMap(({ clientId, beneficiary }) =>
        this.paymentApiService.addBeneficiary(clientId, beneficiary)
          .pipe(
            switchMap(() => this.paymentApiService.fetchBeneficiaries(clientId).pipe(
              map((beneficiaries) => AppActions.editBeneficiariesResult({ beneficiaries })),
            )),
            catchError(() => of(AppActions.editBeneficiariesError())),
          ),
      ),
    ),
  );

  deleteBeneficiary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.deleteBeneficiary),
      concatMap(({ clientId, beneficiaryId }) =>
        this.paymentApiService.deleteBeneficiary(beneficiaryId, clientId)
          .pipe(
            switchMap(() => this.paymentApiService.fetchBeneficiaries(clientId).pipe(
              map((beneficiaries) => AppActions.editBeneficiariesResult({ beneficiaries })),
            )),
            catchError(() => of(AppActions.editBeneficiariesError())),
          ),
      ),
    ),
  );

  fetchInfoXs2aDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchInfoXs2aDialog),
      switchMap(({ financialInstitutionId, importIban }) => {
          if (financialInstitutionId) {
            return this.xs2aApiService.fetchFinancialInstitution(financialInstitutionId).pipe(
              map((financialInstitution) =>
                AppActions.fetchInfoXs2aDialogResult({
                  financialInstitutionId,
                  financialInstitutions: [financialInstitution],
                  importIban,
                })),
              catchError(() => of(AppActions.fetchInfoXs2aDialogError())),
            );
          } else {
            return this.xs2aApiService.fetchFinancialInstitutions().pipe(
              map((financialInstitutions) =>
                AppActions.fetchInfoXs2aDialogResult({
                  financialInstitutions,
                  importIban,
                }),
              ),
              catchError(() => of(AppActions.fetchInfoXs2aDialogError())),
            );
          }
        },
      ),
    ),
  );

  openXs2aDialog$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AppActions.fetchInfoXs2aDialogResult),
        tap(({ financialInstitutionId, importIban }) =>
          this.matDialog.open(Xs2aDialogComponent, {
            disableClose: true,
            data: { financialInstitutionId, importIban },
          }),
        ),
      ),
    { dispatch: false },
  );

  fetchAiarInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchAiarInfo),
      switchMap(({ financialInstitutionId }) =>
        this.xs2aApiService.fetchAiarInfo(financialInstitutionId).pipe(
          map((aiarInfo) => AppActions.fetchAiarInfoResult({ aiarInfo })),
          catchError(() => of(AppActions.fetchAiarInfoError())),
        ),
      ),
    ),
  );

  createAiar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.createAiar),
      concatMap(({ financialInstitutionId, forImport, customerReference, ibans }) =>
        this.xs2aApiService.createAiar(financialInstitutionId, forImport, customerReference, ibans).pipe(
          map((redirectUri) => AppActions.createAiarResult({ redirectUri })),
          catchError(() => of(AppActions.createAiarError())),
        ),
      ),
    ),
  );

  redirectToFinancialInstitution$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.createAiarResult),
      tap(({ redirectUri }) => {
        this.document.location = redirectUri;
      }),
      map(() => AppActions.closeDialog({ message: 'REDIRECTING_TO_FINANCIAL_INSTITUTION' })),
    ),
  );

  processRedirectFromFinancialInstitution$ = createEffect(() =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        map(action => action.payload.routerState),
        filter(({ url }) => url.matchesUrl(AppRoute.Xs2aRedirect.split('/'))),
        tap(({ root: { queryParams } }) =>
          this.matDialog.open(Xs2aRedirectDialogComponent, {
            disableClose: true,
            data: { queryParams },
          }),
        ),
      ),
    { dispatch: false },
  );

  createAiarAuthorization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.createAiarAuthorization),
      concatMap(({ queryParams }) =>
        this.xs2aApiService.createAiarAuthorization(queryParams).pipe(
          map((aiarAuthorization) => AppActions.createAiarAuthorizationResult({ aiarAuthorization })),
          catchError(() => of(AppActions.createAiarAuthorizationError())),
        ),
      ),
    ),
  );

  closeXs2aRedirectDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.closeXs2aRedirectDialog),
      tap(() => this.router.navigateByUrl(AppRoute.BankAccounts)),
      map(() => AppActions.closeDialog({})),
    ),
  );

  processRedirectFromIsabel$ = createEffect(() =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        map(action => action.payload.routerState),
        filter(({ url }) => url.matchesUrl(AppRoute.IsabelRedirect.split('/'))),
        tap(({ root: { queryParams } }) =>
          this.matDialog.open(IsabelRedirectDialogComponent, {
            disableClose: true,
            data: { queryParams },
          }),
        ),
      ),
    { dispatch: false },
  );

  createIsabelAuthorization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.createIsabelAuthorization),
      concatMap(({ code }) =>
        this.isabelApiService.createIsabelAuthorization(code).pipe(
          map(() => AppActions.createIsabelAuthorizationSuccess()),
          catchError(() => of(AppActions.createIsabelAuthorizationError())),
        ),
      ),
    ),
  );

  closeIsabelRedirectDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.closeIsabelRedirectDialog),
      tap(() => this.router.navigateByUrl(AppRoute.Settings + '/isabel-connect')),
      map(() => AppActions.closeDialog({})),
    ),
  );

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(LOCAL_STORAGE) private readonly localStorage: Storage,
    private readonly actions$: Actions,
    private readonly addClientApiService: AddClientApiService,
    private readonly counterApiService: CounterApiService,
    private readonly documentApiService: DocumentApiService,
    private readonly maintenanceModeApiService: MaintenanceModeApiService,
    private readonly matDialog: MatDialog,
    private readonly officeApiService: OfficeApiService,
    private readonly paymentApiService: PaymentApiService,
    private readonly respondToDocumentApiService: RespondToDocumentApiService,
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly userApiService: UserApiService,
    private readonly xs2aApiService: Xs2aApiService,
    private readonly isabelApiService: IsabelApiService,
  ) {
  }
}
