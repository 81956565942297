import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
}                                 from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA }        from '@angular/material/dialog';
import {
  combineLatest,
  distinctUntilChanged,
  map,
  startWith,
  Subject,
  takeUntil,
}                                 from 'rxjs';
import { Xs2aAuthorizationModel } from '~domain/enums';
import {
  AiarInfo,
  FinancialInstitution,
  Xs2aDetailedFormData,
}                                 from '~domain/types';
import {
  DialogButton,
  DialogConfig,
}                                 from '~shared/components';
import { RbDialogForm }           from '~shared/directives';
import { ValidatorService }       from '../core/validators';
import { Xs2aService }            from './xs2a.service';

export type Xs2aRequest = {
  financialInstitutionId?: string,
  importIban?: string;
};

@Component({
  selector: 'rb-xs2a-dialog',
  templateUrl: './xs2a-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Xs2aDialogComponent extends RbDialogForm implements OnInit, OnDestroy {
  override form = this.fb.group({
    financialInstitution: this.fb.control<FinancialInstitution | null>(null),
    detailed: this.fb.control<Xs2aDetailedFormData>({
      customerReference: '',
      ibans: [''],
    }),
  });
  financialInstitutions$ = this.xs2aService.financialInstitutions$;
  aiarInfoIsLoading$ = this.xs2aService.aiarInfoIsLoading$;
  aiarInfo$ = this.xs2aService.aiarInfo$;
  createAiarIsLoading$ = this.xs2aService.createAiarIsLoading$;
  dialogConfig: DialogConfig = {
    title: this.request.importIban ? 'APP.XS2A.IMPORT.TITLE' : 'APP.XS2A.TITLE',
    closeable: true,
    errorKey$: this.errorKey$,
    errorContext: 'APP.XS2A.ERROR',
    buttons: [
      {
        id: DialogButton.Cancel,
        label: 'APP.BUTTON.CANCEL.LABEL',
        icon: 'cross',
        style: 'secondary',
      },
      {
        id: DialogButton.OK,
        label: 'APP.BUTTON.CONFIRM.LABEL',
        icon: 'check',
        style: 'primary',
        autoClose: false,
        disabled$: this.submitDisabled$,
        isLoading$: this.createAiarIsLoading$,
      },
    ],
  };
  protected readonly Xs2aAuthorizationModel = Xs2aAuthorizationModel;
  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public request: Xs2aRequest,
    protected override readonly fb: NonNullableFormBuilder,
    protected override readonly validatorService: ValidatorService,
    private readonly xs2aService: Xs2aService,
  ) {
    super(fb, validatorService);
  }

  get selectedFinancialInstitution() {
    return this.form.controls.financialInstitution.value;
  }

  get instructions() {
    return this.selectedFinancialInstitution
      ? this.xs2aService.getInstructions(this.selectedFinancialInstitution, !!this.request.importIban)
      : [];
  }

  get importantMessages() {
    return this.selectedFinancialInstitution
      ? this.xs2aService.getImportantMessages(this.selectedFinancialInstitution, !!this.request.importIban)
      : [];
  }

  override get submitDisabled$() {
    return combineLatest([
      super.submitDisabled$,
      this.form.controls.financialInstitution.valueChanges.pipe(startWith(null)),
      this.aiarInfo$,
    ]).pipe(
      map(([disabled, , aiarInfo]) => disabled || (this.selectedFinancialInstitution?.authorizationModel === Xs2aAuthorizationModel.detailed && !aiarInfo)),
      distinctUntilChanged(),
    );
  }

  ngOnInit() {
    if (this.request.financialInstitutionId) {
      this.financialInstitutions$
        .pipe(takeUntil(this.destroy$))
        .subscribe((financialInstitutions) => {
          this.form.controls.financialInstitution.setValue(
            financialInstitutions.find(fi => fi.id === this.request.financialInstitutionId) ?? null,
          );
        });
    }
    if (this.request.importIban) {
      this.aiarInfo$
        .pipe(takeUntil(this.destroy$))
        .subscribe(aiarInfo => {
          this.form.controls.detailed.setValue({
            customerReference: '',
            ibans: (aiarInfo?.isabelBankAccounts ?? [])
              .filter(bankAccount => bankAccount.iban === this.request.importIban)
              .map(bankAccount => bankAccount.iban),
          });
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFinancialInstitutionChange() {
    this.clearDetailed();
  }

  onNext() {
    if (!this.form.controls.financialInstitution.value) {
      return;
    }
    this.xs2aService.fetchAiarInfo(this.form.controls.financialInstitution.value.id);
  }

  onPrevious() {
    this.clearDetailed();
  }

  onDialogButtonClick(dialogButton: DialogButton, aiarInfo: AiarInfo | null) {
    if (dialogButton !== DialogButton.OK || !this.form.valid || !this.form.controls.financialInstitution.value) {
      return;
    }
    const customerReference = aiarInfo?.customerReferenceRequired ? this.form.controls.detailed.value.customerReference : undefined;
    const ibans = aiarInfo
      ? [
        ...this.form.controls.detailed.value.ibans.filter(iban => iban !== ''),
        ...aiarInfo.activeBankAccounts.map(account => account.iban),
      ] : undefined;
    this.xs2aService.createAiar(
      this.form.controls.financialInstitution.value.id,
      !!this.request.importIban,
      customerReference,
      ibans,
    );
  }

  private clearDetailed() {
    this.form.controls.detailed.setValue({
      customerReference: '',
      ibans: [''],
    });
    this.xs2aService.clearAiarInfo();
  }
}
