import { HttpClient }  from '@angular/common/http';
import {
  Inject,
  Injectable,
}                      from '@angular/core';
import {
  map,
  Observable,
}                      from 'rxjs';
import { Page }        from '~domain/types';
import { ENVIRONMENT } from '../app.constants';

@Injectable({
  providedIn: 'root',
})
export class CounterApiService {

  constructor(
    @Inject(ENVIRONMENT)
    private readonly environment: Environment,
    private readonly http: HttpClient,
  ) {
  }

  getNumberOfClientsWithNotifications(): Observable<number> {
    return this.http.get<Page<object>>(`${this.environment.api}/clients`, {
      params: {
        page: 0,
        size: 1,
        archived: false,
        openNotificationsOnly: true,
      },
    })
      .pipe(map(page => page.totalElements));
  }

  getNumberOfOpenNotifications(): Observable<number> {
    return this.http.get<Page<object>>(`${this.environment.api}/notifications`, {
      params: {
        page: 0,
        size: 1,
        onlyUnprocessed: true,
      },
    })
      .pipe(map(page => page.totalElements));
  }

  getNumberOfQueuedPayments(): Observable<number> {
    return this.http.get<Page<object>>(`${this.environment.api}/payments`, {
      params: {
        page: 0,
        size: 1,
        status: 'NOT_SUBMITTED',
      },
    })
      .pipe(map(page => page.totalElements));
  }

  getNumberOfUnlinkedAccounts(): Observable<number> {
    return this.http.get<Page<object>>(`${this.environment.api}/bank-accounts`, {
      params: {
        page: 0,
        size: 1,
        active: true,
        linked: false,
      },
    })
      .pipe(map(page => page.totalElements));
  }

  getNumberOfAccountsWithWarning(): Observable<number> {
    return this.http.get<Page<object>>(`${this.environment.api}/bank-accounts`, {
      params: {
        page: 0,
        size: 1,
        active: true,
        onlyWithWarning: true,
      },
    })
      .pipe(map(page => page.totalElements));
  }
}
