<div *ngIf="bankAccount" class="flex gap-[0.5rem] items-center">
  <mat-icon *ngIf="!bankAccount.apiProduct && !dummyOffice"
            [matTooltip]="'APP.BANK_ACCOUNTS.API_PRODUCT.IMPORT.TOOLTIP' | translate"
            class="icon-10 icon-secondary"
            svgIcon="arrow-down"></mat-icon>
  <img *ngIf="bankAccount.apiProduct === ApiProduct.ISABEL"
       [matTooltip]="'APP.BANK_ACCOUNTS.API_PRODUCT.ISABEL.TOOLTIP' | translate"
       alt="Isabel 6 icon"
       class="h-[1rem] inline-block"
       src="../../assets/img/isabel-6-logo-sm.svg" />
  <rb-bank-account-status *ngIf="bankAccount.status && bankAccount.status !== BankAccountStatus.ACTIVE"
                          [bankAccount]="bankAccount"></rb-bank-account-status>
</div>
