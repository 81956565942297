import {
  ClientType,
  Feature,
  Language,
}                       from '../enums';
import { Address }      from './address';
import { JusticePeace } from './justice-peace';
import { LabourCourt }  from './labour-court';

export type Office = {
  name: string;
  clientTypes: ClientType[];
  dummy: boolean;
  features: Feature[];
  allowImportXs2a: boolean;
  language: Language;
  address?: Address;
  ibans: string[];
  defaultJusticePeace?: JusticePeace;
  defaultLabourCourt?: LabourCourt;
};

export type OfficeFormData = {
  name: string,
  language: string,
  street: string;
  number: string;
  postalCode: string;
  municipality: string;
  ibans: string[],
  defaultJusticePeaceId: string | null,
  defaultLabourCourtId: string | null,
};
