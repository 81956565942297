<div class="w-full h-full">
  <div class="flex place-content-center gap-x-[1rem] p-[0.5rem] bg-tertiary">
    <button (click)="zoomOut()"
            *ngIf="scale$ | async as scale"
            [disabled]="scale <= 1.00001"
            class="button-secondary"
            mat-icon-button>
      <mat-icon svgIcon="minus"></mat-icon>
    </button>
    <button (click)="zoomIn()"
            *ngIf="scale$ | async as scale"
            [disabled]="scale >= 7.5"
            class="button-secondary"
            mat-icon-button>
      <mat-icon svgIcon="plus"></mat-icon>
    </button>
  </div>
  <div #container class="bg-secondary h-[calc(100%-3rem)] p-[1rem] overflow-auto">
    <img *ngIf="scale$ | async as scale"
         [alt]="alt"
         [src]="src"
         [style.height.px]="scale * originalHeight"
         [style.width.px]="scale * originalWidth"
         class="m-auto max-h-none max-w-none" />
  </div>
</div>
