<ng-container *ngIf="singleLine">
  <div class="flex gap-[0.5rem] items-center">
    <ng-container *ngTemplateOutlet="ownerType"></ng-container>
    <ng-container *ngTemplateOutlet="bankAccount.logoUrl ? bankLogo : bankLogoMissing"></ng-container>
    <ng-container *ngTemplateOutlet="label"></ng-container>
    <div class="flex text-sm text-secondary">
      <span>(</span>
      <ng-container *ngTemplateOutlet="iban"></ng-container>
      <span>)</span>
    </div>
    <ng-container *ngIf="showBalance">
      <div class="grow"></div>
      <ng-container *ngTemplateOutlet="balance"></ng-container>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="!singleLine">
  <div class="flex gap-x-[0.5rem] items-center">
    <ng-container *ngTemplateOutlet="ownerType"></ng-container>
    <ng-container *ngTemplateOutlet="label"></ng-container>
    <div *ngIf="showType && type" class="flex text-sm text-secondary">
      <span>(</span>
      <ng-container *ngTemplateOutlet="type"></ng-container>
      <span>)</span>
    </div>
    <mat-icon *ngIf="warning"
              [matTooltip]="warning"
              class="icon-15 icon-warning"
              svgIcon="warning"></mat-icon>
  </div>
  <div class="flex gap-x-[0.5rem] items-center">
    <ng-container *ngTemplateOutlet="bankAccount.logoUrl ? bankLogo : bankLogoMissing"></ng-container>
    <ng-container *ngTemplateOutlet="iban"></ng-container>
    <div *ngIf="showBalance && balance" class="flex text-sm text-secondary">
      <span>(</span>
      <ng-container *ngTemplateOutlet="balance"></ng-container>
      <span>)</span>
    </div>
  </div>
</ng-container>
<ng-template #ownerType>
  <mat-icon [svgIcon]="'ENUM.BANK_ACCOUNT_OWNER_TYPE.' + bankAccount.ownerType + '.ICON' | translate"
            class="icon-15 icon-brand mr-0"></mat-icon>
</ng-template>
<ng-template #bankLogo>
  <img [src]="bankAccount.logoUrl"
       alt="bank logo"
       class="w-[1.5rem] shrink-0" />
</ng-template>
<ng-template #type><span class="text-sm text-secondary no-underline">{{
    'ENUM.BANK_ACCOUNT_TYPE.' + bankAccount.type + '.LABEL' | translate
  }}</span></ng-template>
<ng-template #bankLogoMissing>
  <mat-icon class="icon-15 icon-secondary" svgIcon="question"></mat-icon>
</ng-template>
<ng-template #label>
  <div class="truncate">{{ bankAccount.label }}</div>
</ng-template>
<ng-template #iban><span class="text-sm text-secondary no-underline">{{
    bankAccount.iban | split: { size: 4 }
  }}</span></ng-template>
<ng-template #balance><span [class.text-negative]="(bankAccount.balance || 0) < 0"
                            [class.text-positive]="(bankAccount.balance || 0) > 0"
                            class="shrink-0 text-sm">{{
    (bankAccount.balance | currency: bankAccount.currency || '‎' : 'symbol-narrow') || '-'
  }}</span></ng-template>
