import {
  CurrencyPipe,
  NgIf,
  NgTemplateOutlet,
}                           from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
}                           from '@angular/core';
import { MatIcon }          from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule }  from '@ngx-translate/core';
import { BankAccount }      from '~domain/types';
import { SplitPipe }        from '../pipes';

@Component({
  selector: 'rb-bank-account-details',
  standalone: true,
  imports: [
    CurrencyPipe,
    MatIcon,
    MatTooltipModule,
    NgIf,
    NgTemplateOutlet,
    SplitPipe,
    TranslateModule,
  ],
  templateUrl: './bank-account-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankAccountDetailsComponent {

  @Input(({ required: true }))
  bankAccount = {} as BankAccount;

  @Input()
  singleLine: boolean = false;

  @Input()
  showBalance: boolean = true;

  @Input()
  showType: boolean = false;

  @Input()
  warning?: string;
}
