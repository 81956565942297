import {
  AsyncPipe,
  NgIf,
}                          from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
}                          from '@angular/core';
import {
  MatButton,
  MatIconButton,
}                          from '@angular/material/button';
import { MatIcon }         from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import * as pdfjs          from 'pdfjs-dist';
import {
  PageViewport,
  PDFDocumentProxy,
}                          from 'pdfjs-dist';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'rb-pdf-viewer',
  standalone: true,
  imports: [
    AsyncPipe,
    MatButton,
    MatIcon,
    MatIconButton,
    NgIf,
    TranslateModule,

  ],
  templateUrl: './pdf-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfViewerComponent implements OnChanges {
  @Input({ required: true }) src?: string;

  @ViewChild('container') container?: ElementRef;

  pdf: PDFDocumentProxy | null = null;
  currentPage = 1;
  originalScale = 0;

  scale$ = new BehaviorSubject(0);

  constructor() {
    pdfjs.GlobalWorkerOptions.workerSrc = '/assets/pdfjs/pdf.worker.min.mjs';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['src']) {
      this.initPdf(changes['src'].currentValue).then();
    }
  }

  nextPage() {
    if (this.pdf && this.currentPage < this.pdf.numPages) {
      this.currentPage++;
      this.originalScale = 0;
      this.renderPage().then();
    }
  }

  previousPage() {
    if (this.pdf && this.currentPage > 1) {
      this.currentPage--;
      this.originalScale = 0;
      this.renderPage().then();
    }
  }

  zoomIn() {
    const scale = this.scale$.value;
    if (scale < 7.5) {
      this.scale$.next(this.scale$.value * 1.25);
      this.renderPage().then();
    }
  }

  zoomOut() {
    const scale = this.scale$.value;
    if (scale > this.originalScale + 0.00001) {
      this.scale$.next(scale / 1.25);
      this.renderPage().then();
    }
  }

  async initPdf(blobUrl: string) {
    this.pdf = await this.loadPdf(blobUrl)
    this.currentPage = 1;
    this.originalScale = 0;
    this.renderPage().then();
  }

  async loadPdf(blobUrl: string) {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    const arrayBuffer = await blob.arrayBuffer();
    return pdfjs.getDocument(arrayBuffer).promise;
  }

  getInitialScale(viewPort: PageViewport) {
    if (!this.container) {
      return 0;
    }

    const imageW = viewPort.width;
    const imageH = viewPort.height;
    const containerW = this.container.nativeElement.offsetWidth - 20; // 2 * 10px padding
    const containerH = this.container.nativeElement.offsetHeight - 20; // 2 * 10px padding
    if (imageW <= 0 || imageH <= 0 || containerW <= 0 || containerH <= 0) {
      return 0;
    }
    if ((imageW / imageH) > (containerW / containerH)) {
      return containerW / imageW;
    } else {
      return containerH / imageH;
    }
  }

  async renderPage() {
    if (!this.pdf || !this.container) {
      return;
    }

    const page = await this.pdf.getPage(this.currentPage);

    if (this.originalScale === 0) {
      this.originalScale = this.getInitialScale(page.getViewport({ scale: 1.0 }));
      this.scale$.next(this.originalScale);
    }

    this.container.nativeElement.innerHTML = '';

    const viewport = page.getViewport({ scale: this.scale$.value });
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = viewport.width;
    canvas.height = viewport.height;
    canvas.style.margin = 'auto';

    const renderContext = { canvasContext: context!, viewport };

    page.render(renderContext);
    this.container.nativeElement.appendChild(canvas);
  }
}
