import { Injectable }               from '@angular/core';
import { Store }                    from '@ngrx/store';
import { map }                      from 'rxjs';
import {
  BasicDocumentType,
  Feature,
  FileExtension,
}                                   from '~domain/enums';
import {
  PaymentData,
  RbDocument,
  SimpleDocument,
}                                   from '~domain/types';
import { fetchDocumentPaymentData } from '../state/app.actions';
import { FeatureService }           from './feature.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentPaymentService {

  constructor(
    private readonly featureService: FeatureService,
    private readonly store: Store,
  ) {
  }

  canPayDocument(document: RbDocument | SimpleDocument) {
    return this.featureService.officeHasFeature(Feature.ISABEL_PI).pipe(
      map(hasFeature => hasFeature && !!this.payDocumentType(document)),
    );
  }

  openDocumentPaymentDialog(document: RbDocument | SimpleDocument, clientId: string, reloadAfterSave: boolean, paymentData?: PaymentData) {
    const documentId = document.id;
    const documentType = this.payDocumentType(document);
    if (documentType) {
      this.store.dispatch(fetchDocumentPaymentData({
        documentId,
        documentType,
        clientId,
        paymentData,
        reloadAfterSave,
      }));
    }
  }

  private payDocumentType(document: RbDocument | SimpleDocument) {
    if (document.type === BasicDocumentType.Image
      && [
        FileExtension.BMP,
        FileExtension.GIF,
        FileExtension.JPEG,
        FileExtension.JPG,
        FileExtension.PNG,
        FileExtension.TIF,
        FileExtension.TIFF,
      ].includes(document.extension)) {
      return 'image';
    }
    if (document.type === BasicDocumentType.Document && document.extension === FileExtension.PDF) {
      return 'pdf';
    }
    return null;
  }
}
