import { HttpClient }  from '@angular/common/http';
import {
  Inject,
  Injectable,
}                      from '@angular/core';
import { PaymentData } from '~domain/types';
import { ENVIRONMENT } from '../app.constants';

@Injectable({
  providedIn: 'root',
})
export class DocumentApiService {

  constructor(@Inject(ENVIRONMENT) private readonly environment: Environment,
              private readonly http: HttpClient) {
  }

  downloadDocument(id: string, clientId: string) {
    return this.http.get<Blob>(`${this.environment.api}/clients/${clientId}/documents/${id}`, { responseType: 'blob' as 'json' });
  }

  getDocumentPaymentData(id: string) {
    return this.http.get<PaymentData>(`${this.environment.api}/documents/${id}/payment-data`);
  }
}
