import { CommonModule }              from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
}                                     from '@angular/core';
import { MatIconModule }              from '@angular/material/icon';
import { MatTooltipModule }           from '@angular/material/tooltip';
import { TranslateModule }            from '@ngx-translate/core';
import {
  AccountWithApiProduct,
  AccountWithStatus,
  ApiProduct,
  BankAccountStatus,
}                                     from '~domain/types';
import { BankAccountStatusComponent } from './bank-account-status.component';

@Component({
  selector: 'rb-bank-account-icons',
  standalone: true,
  templateUrl: './bank-account-icons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    BankAccountStatusComponent,
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
  ],
})
export class BankAccountIconsComponent {
  @Input() bankAccount?: AccountWithStatus & AccountWithApiProduct;
  @Input() dummyOffice?: boolean = false;

  protected readonly ApiProduct = ApiProduct;
  protected readonly BankAccountStatus = BankAccountStatus;
}
