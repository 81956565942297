import { Injectable }               from '@angular/core';
import { Store }                    from '@ngrx/store';
import { map }                      from 'rxjs';
import {
  BasicDocumentType,
  Feature,
  FileExtension,
}                                   from '~domain/enums';
import {
  RbDocument,
  SimpleDocument,
}                                   from '~domain/types';
import { fetchDocumentPaymentData } from '../state/app.actions';
import { FeatureService }           from './feature.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentPaymentService {

  constructor(
    private readonly featureService: FeatureService,
    private readonly store: Store,
  ) {
  }

  canPayDocument(document: RbDocument | SimpleDocument) {
    return this.featureService.officeHasFeature(Feature.ISABEL_PI).pipe(
      map(hasFeature => hasFeature && !!this.payDocumentType(document)),
    );
  }

  payDocument(document: RbDocument | SimpleDocument, clientId: string) {
    const documentId = document.id;
    const documentType = this.payDocumentType(document);
    if (documentType) {
      this.store.dispatch(fetchDocumentPaymentData({ documentId, documentType, clientId }));
    }
  }

  private payDocumentType(document: RbDocument | SimpleDocument) {
    return document.type === BasicDocumentType.Image
      ? 'image'
      : (document.type === BasicDocumentType.Document && document.extension === FileExtension.PDF ? 'pdf' : null);
  }
}
