import {
  NgForOf,
  NgIf,
}                          from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
}                          from '@angular/core';
import { MatIcon }         from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import {
  Client,
  SimpleClient,
}                          from '~domain/types';

@Component({
  selector: 'rb-client-details',
  standalone: true,
  imports: [
    MatIcon,
    NgForOf,
    NgIf,
    TranslateModule,
  ],
  templateUrl: './client-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientDetailsComponent {
  @Input(({ required: true }))
  client = {} as Client | SimpleClient;

}
