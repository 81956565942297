import { Xs2aAuthorizationModel } from '~domain/enums/xs2a-authorization-model.enum';

export function authModelInstructionsConfig(forImport: boolean) {
  return new Map<Xs2aAuthorizationModel, string[]>([
    [
      Xs2aAuthorizationModel.detailed,
      [
        'APP.XS2A.INSTRUCTIONS.CLICK_NEXT',
        'APP.XS2A.INSTRUCTIONS.ENTER_CUSTOMER_REFERENCE',
        forImport ? 'APP.XS2A.INSTRUCTIONS.ENTER_IBANS_FOR_IMPORT' : 'APP.XS2A.INSTRUCTIONS.ENTER_IBANS',
        'APP.XS2A.INSTRUCTIONS.CONFIRM_TO_REDIRECT_TO_BANK',
        'APP.XS2A.INSTRUCTIONS.AUTHENTICATE',
        'APP.XS2A.INSTRUCTIONS.CONFIRM_DETAILED',
        'APP.XS2A.INSTRUCTIONS.REDIRECT_TO_ROOV',
      ],
    ],
    [
      Xs2aAuthorizationModel.financialInstitutionOffered,
      [
        'APP.XS2A.INSTRUCTIONS.REDIRECT_TO_BANK',
        'APP.XS2A.INSTRUCTIONS.AUTHENTICATE',
        forImport ? 'APP.XS2A.INSTRUCTIONS.SELECT_ALL_FOR_IMPORT' : 'APP.XS2A.INSTRUCTIONS.SELECT_ALL',
        'APP.XS2A.INSTRUCTIONS.CONFIRM_SELECTION',
        'APP.XS2A.INSTRUCTIONS.REDIRECT_TO_ROOV',
      ],
    ],
    [
      Xs2aAuthorizationModel.global,
      [
        'APP.XS2A.INSTRUCTIONS.REDIRECT_TO_BANK',
        'APP.XS2A.INSTRUCTIONS.AUTHENTICATE',
        'APP.XS2A.INSTRUCTIONS.CONFIRM_GLOBAL',
        'APP.XS2A.INSTRUCTIONS.REDIRECT_TO_ROOV',
      ],
    ],
    [
      Xs2aAuthorizationModel.single,
      [
        'APP.XS2A.INSTRUCTIONS.REDIRECT_TO_BANK',
        'APP.XS2A.INSTRUCTIONS.AUTHENTICATE',
        forImport ? 'APP.XS2A.INSTRUCTIONS.SELECT_ONE_FOR_IMPORT' : 'APP.XS2A.INSTRUCTIONS.SELECT_ONE',
        'APP.XS2A.INSTRUCTIONS.CONFIRM_SELECTION',
        'APP.XS2A.INSTRUCTIONS.REDIRECT_TO_ROOV',
      ],
    ],
  ]);
}

export function authModelImportantMessagesConfig(forImport: boolean) {
  return new Map<Xs2aAuthorizationModel, string[]>([
    [
      Xs2aAuthorizationModel.detailed,
      [
        forImport ? 'APP.XS2A.IMPORTANT.DETAILED_1_FOR_IMPORT' : 'APP.XS2A.IMPORTANT.DETAILED_1',
        forImport ? 'APP.XS2A.IMPORTANT.DETAILED_2_FOR_IMPORT' : 'APP.XS2A.IMPORTANT.DETAILED_2',
      ],
    ],
    [
      Xs2aAuthorizationModel.financialInstitutionOffered,
      [
        forImport ? 'APP.XS2A.IMPORTANT.FINANCIAL_INSTITUTION_OFFERED_1_FOR_IMPORT' : 'APP.XS2A.IMPORTANT.FINANCIAL_INSTITUTION_OFFERED_1',
        forImport ? 'APP.XS2A.IMPORTANT.FINANCIAL_INSTITUTION_OFFERED_2_FOR_IMPORT' : 'APP.XS2A.IMPORTANT.FINANCIAL_INSTITUTION_OFFERED_2',
      ],
    ],
    [
      Xs2aAuthorizationModel.global,
      [
        forImport ? 'APP.XS2A.IMPORTANT.GLOBAL_FOR_IMPORT' : 'APP.XS2A.IMPORTANT.GLOBAL',
      ],
    ],
    [
      Xs2aAuthorizationModel.single,
      [
        'APP.XS2A.IMPORTANT.SINGLE_1',
        forImport ? 'APP.XS2A.IMPORTANT.SINGLE_2_FOR_IMPORT' : 'APP.XS2A.IMPORTANT.SINGLE_2',
      ],
    ],
  ]);
}

export function financialInstitutionInstructionsConfig(forImport: boolean) {
  return new Map<string, string[]>([
    [
      'GKCCBEBB', // Belfius
      [
        'APP.XS2A.INSTRUCTIONS.REDIRECT_TO_BANK',
        'APP.XS2A.INSTRUCTIONS.AUTHENTICATE',
        forImport ? 'APP.XS2A.INSTRUCTIONS.GKCCBEBB_FOR_IMPORT' : 'APP.XS2A.INSTRUCTIONS.GKCCBEBB',
        'APP.XS2A.INSTRUCTIONS.CONFIRM_SELECTION',
        'APP.XS2A.INSTRUCTIONS.REDIRECT_TO_ROOV',
      ],
    ],
    [
      'GEBABEBB', // BNP Paribas Fortis
      [
        'APP.XS2A.INSTRUCTIONS.REDIRECT_TO_BANK',
        'APP.XS2A.INSTRUCTIONS.AUTHENTICATE',
        forImport ? 'APP.XS2A.INSTRUCTIONS.GEBABEBB_FOR_IMPORT' : 'APP.XS2A.INSTRUCTIONS.GEBABEBB',
        'APP.XS2A.INSTRUCTIONS.CONFIRM_SELECTION',
        'APP.XS2A.INSTRUCTIONS.REDIRECT_TO_ROOV',
      ],
    ],
    [
      'BBRUBEBB', // ING
      [
        'APP.XS2A.INSTRUCTIONS.REDIRECT_TO_BANK',
        'APP.XS2A.INSTRUCTIONS.AUTHENTICATE',
        forImport ? 'APP.XS2A.INSTRUCTIONS.BBRUBEBB_FOR_IMPORT' : 'APP.XS2A.INSTRUCTIONS.BBRUBEBB',
        'APP.XS2A.INSTRUCTIONS.CONFIRM_SELECTION',
        'APP.XS2A.INSTRUCTIONS.REDIRECT_TO_ROOV',
      ],
    ],
  ]);
}

export function financialInstitutionImportantMessagesConfig(forImport: boolean) {
  return new Map<string, string[]>([
    [
      'GKCCBEBB', // Belfius
      [
        'APP.XS2A.IMPORTANT.GKCCBEBB_ERROR',
        'APP.XS2A.IMPORTANT.GKCCBEBB_RETRY',
        forImport ? 'APP.XS2A.IMPORTANT.REPEAT_FOR_IMPORT' : 'APP.XS2A.IMPORTANT.REPEAT',
      ],
    ],
    [
      'GEBABEBB', // BNP Paribas Fortis
      [
        'APP.XS2A.IMPORTANT.GEBABEBB_ERROR',
      ],
    ],
    [
      'BBRUBEBB', // ING
      [
        'APP.XS2A.IMPORTANT.BBRUBEBB_LIMIT',
        forImport ? 'APP.XS2A.IMPORTANT.REPEAT_FOR_IMPORT' : 'APP.XS2A.IMPORTANT.REPEAT',
      ],
    ],
    [
      'KREDBEBB', // KBC, KBC Brussels
      [
        forImport ? 'APP.XS2A.IMPORTANT.FINANCIAL_INSTITUTION_OFFERED_1_FOR_IMPORT' : 'APP.XS2A.IMPORTANT.FINANCIAL_INSTITUTION_OFFERED_1',
        forImport ? 'APP.XS2A.IMPORTANT.FINANCIAL_INSTITUTION_OFFERED_2_FOR_IMPORT' : 'APP.XS2A.IMPORTANT.FINANCIAL_INSTITUTION_OFFERED_2',
        'APP.XS2A.IMPORTANT.KREDBEBB',
      ],
    ],
    [
      'CREGBEBB', // CBC
      [
        forImport ? 'APP.XS2A.IMPORTANT.FINANCIAL_INSTITUTION_OFFERED_1_FOR_IMPORT' : 'APP.XS2A.IMPORTANT.FINANCIAL_INSTITUTION_OFFERED_1',
        forImport ? 'APP.XS2A.IMPORTANT.FINANCIAL_INSTITUTION_OFFERED_2_FOR_IMPORT' : 'APP.XS2A.IMPORTANT.FINANCIAL_INSTITUTION_OFFERED_2',
        'APP.XS2A.IMPORTANT.KREDBEBB',
      ],
    ],
  ]);
}
