import {
  AsyncPipe,
  NgIf,
}                          from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
}                          from '@angular/core';
import { MatIconButton }   from '@angular/material/button';
import { MatIcon }         from '@angular/material/icon';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'rb-image-viewer',
  standalone: true,
  imports: [
    AsyncPipe,
    MatIconButton,
    MatIcon,
    NgIf,

  ],
  templateUrl: './image-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageViewerComponent implements OnChanges {
  @Input({ required: true }) src?: string;
  @Input({ required: true }) alt?: string;

  @ViewChild('container') container?: ElementRef;

  originalWidth = 0;
  originalHeight = 0;

  scale$ = new BehaviorSubject(0);

  ngOnChanges(changes: SimpleChanges) {
    if (changes['src']) {
      const img = new Image();
      img.src = changes['src'].currentValue;
      img.onload = () => this.initDimensions(img);
    }
  }

  initDimensions(image: HTMLImageElement) {
    if (this.container) {
      const imageW = image.width;
      const imageH = image.height;
      const containerW = this.container.nativeElement.offsetWidth - 20; // 2 * 10px padding
      const containerH = this.container.nativeElement.offsetHeight - 20; // 2 * 10px padding
      if (imageW <= 0 || imageH <= 0 || containerW <= 0 || containerH <= 0) {
        return;
      }
      if ((imageW / imageH) > (containerW / containerH)) {
        this.originalWidth = imageW > containerW ? containerW : imageW;
        this.originalHeight = imageH * this.originalWidth / imageW;
      } else {
        this.originalHeight = imageH > containerH ? containerH : imageH;
        this.originalWidth = imageW * this.originalHeight / imageH;
      }
      this.scale$.next(1);
    }
  }

  zoomIn() {
    const scale = this.scale$.value;
    if (scale < 7.5) {
      this.scale$.next(this.scale$.value * 1.25);
    }
  }

  zoomOut() {
    const scale = this.scale$.value;
    if (scale > 1.00001) { // .00001 to account for floating point deviations
      this.scale$.next(scale / 1.25);
    }
  }
}
