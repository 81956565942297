<rb-dialog (buttonClick)="onDialogButtonClick($event, aiarInfo)"
           *ngrxLet="aiarInfo$ | async as aiarInfo"
           [config]="dialogConfig">
  <form [formGroup]="form">
    <div class="grid grid-cols-3 gap-x-[4rem]">
      <rb-form-section [scroll]="true"
                       [title]="'APP.XS2A.FINANCIAL_INSTITUTION.TITLE' | translate"
                       class="col-start-1 h-[51rem]">
        <mat-radio-group (change)="onFinancialInstitutionChange()"
                         [formControl]="form.controls.financialInstitution"
                         class="flex flex-col"
                         required>
          <mat-radio-button *ngFor="let financialInstitution of financialInstitutions$ | async"
                            [value]="financialInstitution">
            <div class="flex flex-row gap-x-[0.5rem] items-center">
              <img [src]="financialInstitution.logo"
                   alt="logo of {{ financialInstitution.name }}"
                   class="w-[1.5rem] shrink-0" />
              <span>{{ financialInstitution.name }}</span>
            </div>
          </mat-radio-button>
        </mat-radio-group>
      </rb-form-section>
      <rb-form-section [scroll]="true"
                       [title]="'APP.XS2A.INSTRUCTIONS.TITLE' | translate"
                       class="col-start-2 col-span-2">
        <ng-container *ngIf="!selectedFinancialInstitution">
          {{ 'APP.XS2A.INSTRUCTIONS.SELECT_FINANCIAL_INSTITUTION' | translate }}
        </ng-container>
        <ng-container *ngIf="selectedFinancialInstitution">
          <div class="flex flex-col h-full">
            <div class="flex-grow overflow-scroll h-0">
              <ng-container *ngIf="(aiarInfo$ | async) === null">
                <ol class="list-decimal list-outside ml-[2rem]">
                  <li *ngFor="let instruction of instructions">{{ instruction | translate }}</li>
                </ol>
                <ng-container *ngIf="importantMessages.length > 0">
                  <div class="mt-[4rem] mb-[1rem] flex flex-row gap-x-[0.5rem] text-sm font-bold">
                    <mat-icon class="icon-15 icon-warning" svgIcon="warning"></mat-icon>
                    <span>{{ 'APP.XS2A.IMPORTANT.TITLE' | translate }}</span>
                  </div>
                  <ul class="text-sm list-outside ml-[0.5rem]">
                    <li *ngFor="let importantMessage of importantMessages">{{ importantMessage | translate }}</li>
                  </ul>
                </ng-container>
              </ng-container>
              <rb-xs2a-detailed *ngIf="aiarInfo$ | async as aiarInfo"
                                [aiarInfo]="aiarInfo"
                                [errorKey$]="errorKey$"
                                [financialInstitution]="selectedFinancialInstitution"
                                [forImport]="!!request.importIban"
                                formControlName="detailed"></rb-xs2a-detailed>
            </div>
            <div *ngIf="selectedFinancialInstitution.authorizationModel === Xs2aAuthorizationModel.detailed"
                 class="bg-tertiary mx-[-1rem] mb-[-1rem] px-[0.5rem] py-[1rem] flex flex-col">
              <button (click)="onNext()"
                      *ngIf="(aiarInfo$ | async) === null"
                      class="button-primary place-self-end"
                      mat-button>
                <span>{{ 'APP.BUTTON.NEXT.LABEL' | translate }}</span>
                <mat-icon *ngIf="(aiarInfoIsLoading$ | async) === false"
                          class="order-1 ml-[0.5rem] mr-0"
                          svgIcon="chevron-right"></mat-icon>
                <mat-spinner *ngIf="(aiarInfoIsLoading$ | async) === true"
                             class="order-1 ml-[0.5rem] mr-0"
                             diameter="15"></mat-spinner>
              </button>
              <button (click)="onPrevious()"
                      *ngIf="aiarInfo$ | async"
                      class="button-primary place-self-start"
                      mat-button>
                <mat-icon svgIcon="chevron-left"></mat-icon>
                <span>{{ 'APP.BUTTON.PREVIOUS.LABEL' | translate }}</span>
              </button>
            </div>
          </div>
        </ng-container>
      </rb-form-section>
    </div>
  </form>
</rb-dialog>
