import {
  NgForOf,
  NgIf,
}                             from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
}                             from '@angular/core';
import {
  MatButton,
  MatIconButton,
}                             from '@angular/material/button';
import { MatIcon }            from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { TranslateModule }    from '@ngx-translate/core';
import {
  fileIcon,
  needsBrandColor,
  ShareResponse,
}                             from '~domain/enums';
import {
  ExpandableRow,
  Notification,
}                             from '~domain/types';
import { DatePipe }           from '../pipes';

@Component({
  selector: 'rb-notification-details',
  standalone: true,
  imports: [
    DatePipe,
    DatePipe,
    MatButton,
    MatIcon,
    MatIconButton,
    MatProgressSpinner,
    NgForOf,
    NgIf,
    TranslateModule,
  ],
  templateUrl: './notification-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationDetailsComponent {

  @Input({ required: true })
  notification = {} as Notification & ExpandableRow;
  @Input()
  documentDownloadingId: string | null = '';
  @Input()
  canPayDocument: boolean = false;
  @Input()
  fetchingDocumentPaymentDataId: string | null = '';
  @Output()
  downloadDocument = new EventEmitter<void>();
  @Output()
  payDocument = new EventEmitter<void>();
  @Output()
  respondToDocument = new EventEmitter<ShareResponse>();
  @Output()
  toggle = new EventEmitter<void>();

  protected readonly ShareResponse = ShareResponse;
  protected readonly fileIcon = fileIcon;
  protected readonly needsBrandColor = needsBrandColor;

  doDownloadDocument() {
    this.downloadDocument.emit();
  }

  doPayDocument() {
    this.payDocument.emit();
  }

  doRespondToDocument(response: ShareResponse) {
    this.respondToDocument.emit(response);
  }

  doToggle() {
    this.toggle.emit();
  }
}
