import moment            from 'moment';
import { Moment }        from 'moment/moment';
import { PaymentStatus } from '../enums';
import {
  BankAccount,
  Beneficiary,
  BeneficiaryFormData,
  CreditorRemittanceInformationType,
  SimpleClient,
  SimpleDocument,
}                        from './';

export type PaymentFilters = {
  status: PaymentStatus | null;
};

export type Payment = {
  id: string;
  status: PaymentStatus;
  amount: number;
  counterpartName: string;
  counterpartReference: string;
  remittanceInformationType?: CreditorRemittanceInformationType;
  remittanceInformation?: string;
  executionDate?: Date;
  client?: SimpleClient;
  bankAccount: BankAccount;
  document?: SimpleDocument;
}

export type PaymentData = {
  id?: string;
  documentId?: string;
  clientId?: string;
  accountId?: string;
  amount?: number;
  counterpartName?: string;
  counterpartReference?: string;
  remittanceInformationType?: CreditorRemittanceInformationType;
  remittanceInformation?: string;
  executionDate?: Moment | null;
}

export type PaymentFormData = {
  clientId: string;
  accountId: string;
  amount: number | null;
  beneficiary: Beneficiary | null;
  remittanceInformationType: CreditorRemittanceInformationType;
  remittanceInformation: string;
  executionDate: Moment | null;
  anotherPayment: boolean;
  editBeneficiary: BeneficiaryFormData | null;
}

export function toPaymentData(payment: Payment): PaymentData {
  return {
    id: payment.id,
    remittanceInformation: payment.remittanceInformation,
    remittanceInformationType: payment.remittanceInformationType,
    amount: payment.amount,
    accountId: payment.bankAccount.id,
    clientId: payment.client?.id,
    counterpartName: payment.counterpartName,
    counterpartReference: payment.counterpartReference,
    executionDate: payment?.executionDate ? moment.utc(payment.executionDate) : undefined,
  };
}
