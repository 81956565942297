<rb-dialog (buttonClick)="onDialogButtonClick($event)" [config]="dialogConfig">
  <div class="flex gap-x-[4rem]">
    <rb-view-section *ngIf="request.documentId">
      <ng-container *ngrxLet="paymentDocumentType$ | async as paymentDocumentType">
        <div *ngrxLet="paymentDocumentUrl$ | async as paymentDocumentUrl"
             class="h-[69.9rem] overflow-hidden rounded-[0.5rem] w-[50rem]">
          <rb-image-viewer *ngIf="paymentDocumentType === 'image'"
                           alt="document"
                           class="block h-full w-full"
                           src="{{ paymentDocumentUrl }}"></rb-image-viewer>
          <ng2-pdfjs-viewer *ngIf="paymentDocumentType === 'pdf'"
                            class="block h-full w-full"
                            pdfSrc="{{ paymentDocumentUrl }}"
                            zoom="page-fit"></ng2-pdfjs-viewer>
        </div>
      </ng-container>
    </rb-view-section>
    <form [formGroup]="form" class="grow">
      <rb-edit-payment (fetchBeneficiaries)="fetchBeneficiaries($event)"
                       (saveBeneficiary)="saveBeneficiary($event)"
                       [addPaymentEnabled]="!request.id && !request.documentId"
                       [addPaymentLoading]="addPaymentLoading$ | async"
                       [beneficiariesLoading]="beneficiariesLoading$ | async"
                       [beneficiaries]="beneficiaries$ | async"
                       [beneficiaryEditing]="beneficiaryEditing$ | async"
                       [errorKey$]="errorKey$"
                       [paymentAccounts]="paymentAccounts$ | async"
                       [paymentClients]="paymentClients$ | async"
                       [singleColumn]="!!request.documentId"
                       formControlName="payment">
      </rb-edit-payment>
    </form>
  </div>
</rb-dialog>
