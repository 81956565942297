<div class="w-full h-full">
  <div class="grid grid-cols-3 bg-tertiary">
    <div *ngIf="pdf?.numPages as numPages" class="flex p-[0.5rem]">
      <button (click)="previousPage()"
              *ngIf="numPages > 1"
              [disabled]="currentPage <= 1"
              class="button-secondary"
              mat-button>
        <mat-icon svgIcon="chevron-left"></mat-icon>
        <span>{{ "PDF_VIEWER.PREVIOUS_PAGE" | translate }}</span>
      </button>
    </div>
    <div class="flex justify-center gap-x-[1rem] p-[0.5rem]">
      <button (click)="zoomOut()"
              *ngIf="scale$ | async as scale"
              [disabled]="scale <= originalScale + 0.00001"
              class="button-secondary"
              mat-icon-button>
        <mat-icon svgIcon="minus"></mat-icon>
      </button>
      <button (click)="zoomIn()"
              *ngIf="scale$ | async as scale"
              [disabled]="scale >= 7.5"
              class="button-secondary"
              mat-icon-button>
        <mat-icon svgIcon="plus"></mat-icon>
      </button>
    </div>
    <div *ngIf="pdf?.numPages as numPages" class="flex justify-end p-[0.5rem]">
      <button (click)="nextPage()"
              *ngIf="numPages > 1"
              [disabled]="currentPage >= numPages"
              class="button-secondary"
              mat-button>
        <span>{{ "PDF_VIEWER.NEXT_PAGE" | translate }}</span>
        <mat-icon class="order-1 ml-[0.5rem] mr-0" svgIcon="chevron-right"></mat-icon>
      </button>
    </div>
  </div>
  <div #container class="bg-secondary h-[calc(100%-3rem)] p-[1rem] overflow-auto"></div>
</div>
