import {
  combineReducers,
  createReducer,
  on,
}                       from '@ngrx/store';
import {
  addBeneficiary,
  addPayment,
  addPaymentError,
  addPaymentResult,
  clearAccountUser,
  clearAiarInfo,
  createAiar,
  createAiarAuthorization,
  createAiarAuthorizationError,
  createAiarAuthorizationResult,
  createAiarError,
  createAiarResult,
  createClient,
  createClientError,
  createClientResult,
  createIsabelAuthorization,
  createIsabelAuthorizationError,
  createIsabelAuthorizationSuccess,
  deleteBeneficiary,
  downloadDocument,
  downloadDocumentError,
  downloadDocumentResult,
  editBeneficiariesError,
  editBeneficiariesResult,
  fetchAccountsWithWarningBadgeCountResult,
  fetchAccountUserResult,
  fetchAiarInfo,
  fetchAiarInfoError,
  fetchAiarInfoResult,
  fetchBeneficiaries,
  fetchBeneficiariesError,
  fetchBeneficiariesResult,
  fetchClientBadgeCountResult,
  fetchColorModeResult,
  fetchDocumentPaymentData,
  fetchDocumentPaymentDataError,
  fetchDocumentPaymentDataResult,
  fetchInfoPaymentDialog,
  fetchInfoPaymentDialogError,
  fetchInfoPaymentDialogResult,
  fetchInfoXs2aDialog,
  fetchInfoXs2aDialogError,
  fetchInfoXs2aDialogResult,
  fetchMaintenanceModeResult,
  fetchNotificationBadgeCountResult,
  fetchOfficeResult,
  fetchPaymentBadgeCountResult,
  fetchUnlinkedAccountsBadgeCountResult,
  respondToDocument,
  respondToDocumentError,
  respondToDocumentResult,
  setColorModeResult,
  updateBeneficiary,
  updatePayment,
  updatePaymentError,
  updatePaymentResult,
}                       from './app.actions';
import { initialState } from './app.state';

const colorModeReducer = createReducer(
  initialState.colorMode,
  on(fetchColorModeResult, (state, { colorMode }) => colorMode),
  on(setColorModeResult, (state, { colorMode }) => colorMode),
);

const maintenanceModeReducer = createReducer(
  initialState.maintenanceMode,
  on(fetchMaintenanceModeResult, (state, { maintenanceMode }) => maintenanceMode),
);

const accountUserReducer = createReducer(
  initialState.accountUser,
  on(fetchAccountUserResult, (state, { user }) => user),
  on(clearAccountUser, () => initialState.accountUser),
);

const clientBadgeCountReducer = createReducer(
  initialState.clientBadgeCount,
  on(fetchClientBadgeCountResult, (state, { count }) => count),
);

const notificationBadgeCountReducer = createReducer(
  initialState.notificationBadgeCount,
  on(fetchNotificationBadgeCountResult, (state, { count }) => count),
);

const paymentBadgeBadgeCountReducer = createReducer(
  initialState.paymentBadgeCount,
  on(fetchPaymentBadgeCountResult, (state, { count }) => count),
);

const unlinkedAccountsBadgeCountReducer = createReducer(
  initialState.unlinkedAccountsBadgeCount,
  on(fetchUnlinkedAccountsBadgeCountResult, (state, { count }) => count),
);

const accountsWithWarningBadgeCountReducer = createReducer(
  initialState.accountsWithWarningBadgeCount,
  on(fetchAccountsWithWarningBadgeCountResult, (state, { count }) => count),
);

const officeReducer = createReducer(
  initialState.office,
  on(fetchOfficeResult, (state, { office }) => office),
);

const createClientIsLoadingReducer = createReducer(
  initialState.createClientIsLoading,
  on(createClient, () => true),
  on(createClientResult, () => false),
  on(createClientError, () => false),
);

const documentUpdatingReducer = createReducer(
  initialState.documentUpdating,
  on(respondToDocument, () => true),
  on(respondToDocumentResult, () => false),
  on(respondToDocumentError, () => false),
);

const documentDownloadingIdReducer = createReducer(
  initialState.documentDownloadingId,
  on(downloadDocument, (state, { id }) => id),
  on(downloadDocumentResult, () => null),
  on(downloadDocumentError, () => null),
);

const fetchingDocumentPaymentDataIdReducer = createReducer(
  initialState.fetchingDocumentPaymentDataId,
  on(fetchDocumentPaymentData, (state, { documentId }) => documentId),
  on(fetchDocumentPaymentDataError, () => null),
  on(fetchInfoPaymentDialogResult, () => null),
  on(fetchInfoPaymentDialogError, () => null),
);

const paymentAccountsReducer = createReducer(
  initialState.paymentAccounts,
  on(fetchInfoPaymentDialogResult, (state, { paymentAccounts }) => paymentAccounts),
);

const paymentClientsReducer = createReducer(
  initialState.paymentClients,
  on(fetchInfoPaymentDialogResult, (state, { paymentClients }) => paymentClients),
);

const paymentDocumentTypeReducer = createReducer(
  initialState.paymentDocumentType,
  on(fetchDocumentPaymentDataResult, (state, { documentType }) => documentType),
);

const paymentDocumentUrlReducer = createReducer(
  initialState.paymentDocumentUrl,
  on(fetchDocumentPaymentDataResult, (state, { documentUrl }) => documentUrl),
);

const beneficiariesReducer = createReducer(
  initialState.beneficiaries,
  on(fetchInfoPaymentDialogResult, (state, { beneficiaries }) => beneficiaries),
  on(fetchBeneficiariesResult, (state, { beneficiaries }) => beneficiaries),
  on(editBeneficiariesResult, (state, { beneficiaries }) => beneficiaries),
);

const beneficiariesLoadingReducer = createReducer(
  initialState.beneficiariesLoading,
  on(fetchBeneficiaries, () => true),
  on(fetchBeneficiariesResult, () => false),
  on(fetchBeneficiariesError, () => false),
);

const paymentInfoLoadingReducer = createReducer(
  initialState.paymentInfoLoading,
  on(fetchInfoPaymentDialog, () => true),
  on(fetchInfoPaymentDialogResult, () => false),
  on(fetchInfoPaymentDialogError, () => false),
);

const paymentSavingReducer = createReducer(
  initialState.paymentSaving,
  on(addPayment, () => true),
  on(updatePayment, () => true),
  on(addPaymentResult, () => false),
  on(updatePaymentResult, () => false),
  on(addPaymentError, () => false),
  on(updatePaymentError, () => false),
);

const beneficiaryEditingReducer = createReducer(
  initialState.beneficiaryEditing,
  on(addBeneficiary, () => true),
  on(updateBeneficiary, () => true),
  on(deleteBeneficiary, () => true),
  on(editBeneficiariesError, () => false),
  on(editBeneficiariesResult, () => false),
);

const xs2aInfoIsLoadingReducer = createReducer(
  initialState.xs2aInfoIsLoading,
  on(fetchInfoXs2aDialog, () => true),
  on(fetchInfoXs2aDialogResult, () => false),
  on(fetchInfoXs2aDialogError, () => false),
);

const xs2aFinancialInstitutionsReducer = createReducer(
  initialState.xs2aFinancialInstitutions,
  on(fetchInfoXs2aDialogResult, (state, { financialInstitutions }) => financialInstitutions),
);

const aiarInfoIsLoadingReducer = createReducer(
  initialState.aiarInfoIsLoading,
  on(fetchAiarInfo, () => true),
  on(fetchAiarInfoResult, () => false),
  on(fetchAiarInfoError, () => false),
);

const aiarInfoReducer = createReducer(
  initialState.aiarInfo,
  on(fetchAiarInfoResult, (state, { aiarInfo }) => aiarInfo),
  on(clearAiarInfo, () => null),
);

const createAiarIsLoadingReducer = createReducer(
  initialState.createAiarIsLoading,
  on(createAiar, () => true),
  on(createAiarResult, () => false),
  on(createAiarError, () => false),
);

const createAiarAuthorizationIsLoadingReducer = createReducer(
  initialState.createAiarAuthorizationIsLoading,
  on(createAiarAuthorization, () => true),
  on(createAiarAuthorizationResult, () => false),
  on(createAiarAuthorizationError, () => false),
);

const aiarAuthorizationReducer = createReducer(
  initialState.aiarAuthorization,
  on(createAiarAuthorizationResult, (state, { aiarAuthorization }) => aiarAuthorization),
);

const aiarAuthorizationErrorReducer = createReducer(
  initialState.aiarAuthorizationError,
  on(createAiarAuthorizationError, () => true),
);

const createIsabelAuthorizationIsLoadingReducer = createReducer(
  initialState.createIsabelAuthorizationIsLoading,
  on(createIsabelAuthorization, () => true),
  on(createIsabelAuthorizationSuccess, () => false),
  on(createIsabelAuthorizationError, () => false),
);

const isabelAuthorizationErrorReducer = createReducer(
  initialState.isabelAuthorizationError,
  on(createIsabelAuthorizationError, () => true),
);

export const appReducer = combineReducers(
  {
    colorMode: colorModeReducer,
    maintenanceMode: maintenanceModeReducer,
    accountUser: accountUserReducer,
    clientBadgeCount: clientBadgeCountReducer,
    notificationBadgeCount: notificationBadgeCountReducer,
    paymentBadgeCount: paymentBadgeBadgeCountReducer,
    unlinkedAccountsBadgeCount: unlinkedAccountsBadgeCountReducer,
    accountsWithWarningBadgeCount: accountsWithWarningBadgeCountReducer,
    office: officeReducer,
    createClientIsLoading: createClientIsLoadingReducer,
    documentUpdating: documentUpdatingReducer,
    documentDownloadingId: documentDownloadingIdReducer,
    fetchingDocumentPaymentDataId: fetchingDocumentPaymentDataIdReducer,
    paymentAccounts: paymentAccountsReducer,
    paymentClients: paymentClientsReducer,
    paymentDocumentType: paymentDocumentTypeReducer,
    paymentDocumentUrl: paymentDocumentUrlReducer,
    beneficiaries: beneficiariesReducer,
    beneficiariesLoading: beneficiariesLoadingReducer,
    paymentInfoLoading: paymentInfoLoadingReducer,
    paymentSaving: paymentSavingReducer,
    beneficiaryEditing: beneficiaryEditingReducer,
    xs2aInfoIsLoading: xs2aInfoIsLoadingReducer,
    xs2aFinancialInstitutions: xs2aFinancialInstitutionsReducer,
    aiarInfoIsLoading: aiarInfoIsLoadingReducer,
    aiarInfo: aiarInfoReducer,
    createAiarIsLoading: createAiarIsLoadingReducer,
    createAiarAuthorizationIsLoading: createAiarAuthorizationIsLoadingReducer,
    aiarAuthorization: aiarAuthorizationReducer,
    aiarAuthorizationError: aiarAuthorizationErrorReducer,
    createIsabelAuthorizationIsLoading: createIsabelAuthorizationIsLoadingReducer,
    isabelAuthorizationError: isabelAuthorizationErrorReducer,
  },
  initialState,
);
